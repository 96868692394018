import * as React from 'react';

import './App.css';
import {Box, Chip, LinearProgress, Stack, Typography} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {listEvents} from './backend.js'


function RdvStepHoraire({formik}) {

    const [rdvs, setRdvs] = React.useState([]);
    const [apiError, setApiError] = React.useState(null);
    const [linearProgressDisplay, setLinearProgressDisplay] = React.useState('block');
    const [selectedChip, setSelectedChip] = React.useState(null);

    React.useEffect(() => {
        listEvents()
            .then(data => {
                if (data) {
                    setRdvs(data)
                    setApiError(null)
                } else {
                    setApiError("No Data")
                }
                setLinearProgressDisplay('none')
            }).catch(function (error) {
            console.log(error)
            setApiError(error)
            setLinearProgressDisplay('none')
        })
        return;
    }, [])

   React.useEffect(() => {
     if (formik.values.date && formik.values.time) {
        setSelectedChip(formik.values.date + '-' + formik.values.time)
    }

   }, [formik])


    const handleTimeClick = async (event, date, time) => {

        await formik.setFieldValue('date', date);
        await formik.setFieldValue('time', time);
        setSelectedChip(date + '-' + time)

    };


    return (
        <Box sx={{p: 3, border: '1px dashed grey'}}>
            {(apiError) ? "Pas de rendez-vous disponibles en ligne pour l'instant. Veuillez nous contacter par téléphone. " : ''}
            <LinearProgress sx={{display: linearProgressDisplay}}/>
            <Typography variant="h5" sx={{display: (rdvs.length > 0) ? 'block' : 'none'}}>
                Rendez-vous disponibles pour {formik.values.firstName} {formik.values.lastName} :
            </Typography>
            {rdvs.map((rdv, i) => (

                (rdv.times.length === 0) ? null : (

                    <Stack spacing={1} align="left">
                        <Typography variant="h6">
                            {rdv.ddate}
                        </Typography>
                        <Grid container spacing={2} align="left">

                            {rdv.times.map((rdvTime, j) => (
                                <Grid xs={4} sm={1}>
                                    <Chip
                                        label={rdvTime}
                                        variant={(selectedChip && (selectedChip === rdv.date + '-' + rdvTime)) ? "filled" : "outlined"}
                                        color="primary"
                                        name="chip"
                                        onClick={(e) => {
                                            handleTimeClick(e, rdv.date, rdvTime);
                                        }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Stack>
                )

            ))}
        </Box>
    );
}

export default RdvStepHoraire;
